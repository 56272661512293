<template>
  <div class="menber-dialog">
    <el-dialog
      title="发布接管"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="1338px"
    >
      <div class="form">
        <el-form
          ref="form"
          :model="form"
          :inline="true"
          label-width="100px"
          :rules="formRules"
        >
          <el-form-item label="农服商名称" prop="companyName">
            <el-input v-model="form.companyName" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contactMan">
            <el-input v-model="form.contactMan" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone" disabled></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address" style="wdith: 300px" disabled>
            </el-input>
          </el-form-item>
          <el-form-item label="接管类型" prop="serviceType">
            <el-radio-group v-model="form.serviceType">
              <el-radio :label="'1'">全程托管</el-radio>
              <el-radio :label="'2'">环节托管</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="意向区域" prop="intentionRegionCode">
            <el-cascader
              ref="cascader"
              v-model="form.intentionRegionCode"
              :props="props"
              multiple
              @change="cascaderChange"
              :show-all-levels="false"
              :options="regionOptions"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="作物类型" prop="serviceScope">
            <el-select
              :no-data-text="loadText"
              v-model="form.serviceScope"
              filterable
              placeholder="请选择托管作物"
              multiple
              clearable
              @change="changeCropType"
            >
              <el-option
                v-for="item in cropArr"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="农事环节" prop="operScope">
            <el-select
              v-model="form.operScope"
              placeholder="请选择托管环节"
              multiple
            >
              <el-option
                v-for="item in nshjArr"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="可接管面积" prop="serviceArea">
            <el-input v-model="form.serviceArea"
              ><template slot="append">亩</template></el-input
            >
          </el-form-item>
          <el-form-item label="已接管面积" prop="serviceAreaOlde">
            <el-input v-model="form.serviceAreaOlde"
              ><template slot="append">亩</template></el-input
            >
          </el-form-item>

          <el-form-item
            label="经营相关照片"
            prop="phonts"
            style="width: 100% !important"
          >
            <el-upload
              class="upload-demo"
              action=""
              :file-list="fileList"
              :before-upload="beforeUpload2"
              :on-change="uploadImages"
              :on-remove="removeImages"
              :accept="'.jpg,.jpeg,.gif,.png'"
              multiple
              :limit="6"
              :auto-upload="false"
              list-type="picture-card"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip" style="margin-top: -6px">
                单张图片最大6M，最多上传6张，支持:JPG/JPEG/GIF/PNG格式。
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item
            label="其他说明"
            prop="remark"
            style="width: 60% !important"
          >
            <el-input
              v-model="form.remark"
              style="width: 400px !important"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="gobackView">取消</el-button>
        <el-button offset="200px" type="primary" @click="submit" v-if="!isEdit"
          >发布</el-button
        >
        <el-button type="primary" @click="edit" v-if="isEdit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { split } from "@/js/utils/util";
import { getRegionList } from "@/api/projectManage";
import {
  getCompanyCrop,
  getCropContent,
  updateCompany,
  getXzqArr,
} from "@/api/add";
import { companysave } from "@/api/projectManage";
import { mapGetters } from "vuex";
import { removeEmptyChildren } from "@/js/utils/util";
import { uploadFile, deleteFile } from "@/api/fileApi";

export default {
  data() {
    return {
      loadText: "无数据（请先选择意向区域）",
      isEdit: false,
      dialogVisible: true,
      nshjArr: [],
      areaType: [],
      regionOptions: [{}],
      props: {
        expandTrigger: "hover",
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
        multiple: true,
      },
      form: {
        companyName: "",
        contactMan: "",
        phone: "",
        address: "",
        serviceType: "1",
        serviceScope: [],
        operScope: [],
        areaName: "",
      },
      cropArr: [],
      formRules: {
        intentionRegionCode: [
          { required: true, message: "请选择意向区域", trigger: "change" },
        ],
        serviceType: [
          { required: true, message: "请选择接管类型", trigger: "change" },
        ],
        serviceScope: [
          { required: true, message: "请选择作物类型", trigger: "change" },
        ],
        operScope: [
          { required: true, message: "请选择农事环节", trigger: "change" },
        ],
      },
      areaPath: "",
      areaPathLabels: "",
      fileList: [],
      baseUrl: window.globalUrl.HOME_API,
      str: "",
    };
  },

  created() {
    let roleInfo = this.identityObj ? this.identityObj : {};
    this.form.companyName = roleInfo.identityName;
    this.form.contactMan = roleInfo.contactName;
    this.form.phone = roleInfo.contactPhone;
    this.form.areaCode = roleInfo.areaCode;
    this.form.address = roleInfo.areaFullName;
    // this.getCompanyCrop();
    this.getRegions();
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  watch: {
    // 监听 form.serviceScope 数组长度变化
    'form.serviceScope.length': function(newValue, oldValue) {
      // 当 form.serviceScope 数组的长度减少时执行的操作
      if (newValue < oldValue) {
        // Do something when the length decreases
        console.log('服务范围数组长度减少了', newValue);
        // 假设你想要清空 nshjArr 数组，可以使用 Vue.set 或者 this.$set
        this.form.operScope=[]
        // Vue.set(this, 'form.operScope', []);
      }
    },
  },
  methods: {
    // 过来行政区编号
    filterRegionCode(arr) {
      let list = [];
      arr.forEach((item) => {
        list.push([item.slice(0, 2), item.slice(0, 4), item.slice(0, 6), item]);
        console.log("693996", item);
      });
      return list;
    },

    // 编辑回显数据
    editObj(obj) {
      this.isEdit = true;
      this.form = {
        ...obj,
        operScope: obj.operScope.split(","),
        serviceScope: obj.serviceScope.split(","),
      };
      this.areaPath = obj.intentionRegionCode;
      this.areaPathLabels = obj.intentionRegion;
      this.form.intentionRegionCode = this.filterRegionCode(
        obj.intentionRegionCode.split(",")
      );
      this.$forceUpdate();
      this.changeCropType(this.form.serviceScope);
      let fileList = obj.photos ? JSON.parse(obj.photos) : [];
      this.getViewUrl(fileList);
    },
    // 经营照片回显
    getViewUrl(arr) {
      arr.forEach(async (item) => {
        item.url2 = item.url;
        item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.url;
      });
      this.fileList = arr;
    },
    // 意向区域修改
    cascaderChange(arr) {
      if (arr.length == 1 && arr[0].length == 4) {
        this.baseCountry = arr[0][2];
        this.getCompanyCrop();
      }
      if (arr.length == 0) {
        this.baseCountry = null;
        this.form.intentionRegionCode = [];
        this.cropArr = [];
        this.nshjArr = [];
        this.form.operScope = [];
        console.log(" this.nshjArr12111", this.nshjArr);
        this.loadText = "无数据（请先选择意向区域）";
      }
      if (arr.length > 1) {
        let filterArr = [];
        arr.forEach((item) => {
          if (item[2] != this.baseCountry) {
            this.$message.info("仅支持选单个县区下的街道");
          } else {
            filterArr.push(item);
          }
        });
        this.form.intentionRegionCode = filterArr;
        this.getCompanyCrop();
      }
      this.form.serviceScope = [];
      let newArr = [];
      arr.forEach((item) => {
        // console.log(removeEmptyChildren(item));
        this.str = item[0] + "/" + item[1] + "/" + item[2];
        this.traverseHierarchy(this.regionOptions);
        console.log(this.str);
        console.log("ARR", item[0] + "/" + item[1] + "/" + item[2]);
        newArr.push(item[item.length - 1]);
      });
      const nodes = this.$refs.cascader.getCheckedNodes();
      console.log("nodes", nodes);
      let newNodes = [];
      nodes.forEach((item) => {
        if (newArr.indexOf(item.value) > -1) {
          newNodes.push(item);
        }
      });
      let areaPath = [];
      let areaPathLabels = [];
      newNodes.forEach((item) => {
        areaPath.push(item.value);
        areaPathLabels.push(item.label);
      });
      this.areaPath = areaPath.join(",");
      this.areaPathLabels = areaPathLabels.join(",");
    },
    // 删文件
    async deleteFile(url) {
      let res = await deleteFile({ ossFilePath: url });
      if (res.code == 0 && res.data == true) {
        console.log("删除成功");
      }
    },
    // 移除照片
    removeImages(file, fileList) {
      this.fileList = fileList;
      // if(this.isEdit){
      //   this.deleteFile(file.url2);
      // }
    },
    // 上传
    async uploadImages(file, filelist) {
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF 格式的图片");
      }

      if (!isLt6M) {
        this.$message.error("上传图片或文件大小不能超过 6MB");
      }
      if (isJPG && isLt6M) {
        let res = await this.uploadFile(file, filelist);
      } else {
        this.$message.error("上传失败");
      }
    },
    // 文件上传
    async uploadFile(file, filelist) {
      console.log("file", file);
      // this.beforeUpload2(file)
      // console.log("77777",this.beforeUpload2(file));

      let param = new FormData();
      param.append("catalog", "vip");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      console.log("图片上传", res);
      if (res.code == 0) {
        file.url2 = res.data.url;
      } else {
        this.$message.error(res.msg ? res.msg : "上传失败");
      }
      this.fileList = filelist;
    },
    // 上传前
    beforeUpload2(file) {
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF 格式的图片");
      }

      if (!isLt6M) {
        this.$message.error("上传图片或文件大小不能超过 6MB");
      }

      return isJPG || isLt6M;
    },
    // 获取作物类型根据行政区
    async getCompanyCrop() {
      let res = await getCompanyCrop({
        areaCode: this.baseCountry,
      });
      if (res.code == 0) {
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            item.label = item.categoryName;
            item.value = item.id;
          });
          this.cropArr = res.data;
        } else {
          this.loadText = "该意向区域下无作物类型可选";
          this.cropArr = [];
        }
      } else {
        this.loadText = "该意向区域下无作物类型可选";
        this.cropArr = [];
      }
    },
    // 农事环节
    async getCropContent(id) {
      console.log("id", id);
      let res = await getCropContent(id);
      console.log("res", res);
      if (res.data && res.code == 0) {
        res.data.forEach((item) => {
          item.label = item.categoryName;
          item.value = item.id;
        });
        return res.data;
      }
    },
    // 更改托管作物
    async changeCropType(name) {
      if(this.form.serviceScope.length == 0){
        // this.nshjArr= [];
        this.form.operScope=[];
      }
      console.log("name", name);
      let arr = [];
      this.tghjArr = [];
      name.forEach((pz) => {
        this.cropArr.forEach(async (item) => {
          if (item.label == pz) {
            arr.push(item.value);
          }
        });
      });
      this.nshjArr = await this.getCropContent(arr.join(","));
      console.log("this.nshjArr", this.nshjArr);
    },
    // 过滤掉部分不可选的
    addDisabled(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          if (arr[i].level != 4) {
            arr[i].disabled = true;
          }
          this.addDisabled(arr[i].children);
        } else {
          if (arr[i].level != 4) {
            arr[i].disabled = true;
          }
        }
      }
      return arr;
    },
    async getRegions() {
      // 获取地区信息
      let res = await getXzqArr({
        level: 4,
      });
      if (res.code == 0) {
        let hasRegion = setInterval(() => {
          res.data = this.addDisabled(res.data);
          this.regionOptions = removeEmptyChildren(res.data);
          console.log("this.regionOptions", this.regionOptions);

          console.log(JSON.parse(JSON.stringify(this.regionOptions[0])));
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
      }
    },
    traverseHierarchy(nodes) {
      for (let node of nodes) {
        if (node.children) {
          // console.log("当前节点:", node);

          for (let child of node.children) {
            // console.log(child);
            if (child.fullPathCode == this.str) {
              // && this.str == child.fullPathCode
              this.areaName = child.fullPathName;
              // console.log("当前level:", child.fullPathName);
              // console.log("当前level888:", child.fullPathCode);
            }
            this.traverseHierarchy([child]); // 递归处理子级节点
          }
        } else {
          // console.log("当前节点为叶子节点:", node);
        }
      }
    },
    // 构造文件上传字符串
    getFileParams(arr) {
      let list = [];
      arr.forEach((item) => {
        list.push({
          url: item.url2,
          name: item.name,
        });
      });
      return JSON.stringify(list);
    },
    // 编辑
    edit() {
      // 将字符串按照 '/' 分割成数组
      const parts = this.areaPathLabels.split("/");

      // 获取最后一个部分
      const lastPart = parts[parts.length - 1];

      console.log("this.areaPath", this.areaPath);
      this.form.createBy = this.userInfo.id;
      this.form.phonts =
        this.fileList.length > 0 ? this.getFileParams(this.fileList) : "";
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = Object.assign(this.form, {
            serviceScope: this.form.serviceScope.join(","),
            operScope: this.form.operScope.join(","),
            intentionRegion: lastPart,
            intentionRegionCode: this.areaPath,
            areaCode: this.identityObj.areaCode,
            areaName: this.areaName,
          });
          let res = await updateCompany(params);
          if (res.code == 0) {
            this.$message.success("修改成功");
            this.$parent.reset();
            this.handleClose();
          } else {
            this.$message("发布失败");
          }
        } else {
          this.$message.error("请完善接管信息");
        }
      });
    },
    // 提交
    submit() {
      this.form.createBy = this.userInfo.id;
      this.form.phonts =
        this.fileList.length > 0 ? this.getFileParams(this.fileList) : "";
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = Object.assign(this.form, {
            serviceScope: this.form.serviceScope.join(","),
            operScope: this.form.operScope.join(","),
            intentionRegion: this.areaPathLabels,
            intentionRegionCode: this.areaPath,
            areaCode: this.identityObj.areaCode,
            areaName: this.areaName,
          });
          let res = await companysave(params);
          if (res.code == 0) {
            this.$message.success("发布成功");
            this.$parent.reset();
            this.handleClose();
          } else {
            this.$message("发布失败");
          }
        } else {
          this.$message.error("请完善接管信息");
        }
      });
    },
    gobackView() {
      this.$emit("closeDialog", false);
    },
    handleClose() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  width: 40% !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog {
  margin-top: 10vh !important;
}
/deep/.el-dialog__body {
  padding-left: 15%;
  padding-bottom: 0 !important;
}
.sends-info {
  padding-bottom: 20px;
  text-align: left;
  .form {
    text-align: left;
    // width: 679px;
    margin: 0 auto;
    margin-top: 28px;
    padding: 0 150px 0px 50px;

    .el-radio-group {
      text-align: left;
    }

    .el-radio-button,
    .el-radio-button__inner {
      margin-right: 16px;
      width: 89px;
    }
  }

  .form-item-con {
    height: 50px;
  }
}
</style>
