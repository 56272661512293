<template>
  <div class="menber-dialog">
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <el-form ref="form" label-width="160px">
        <el-form-item label="农服商名称:">{{
          currentItem.companyName
        }}</el-form-item>
        <el-form-item label="联系人:" style="width: 300px">
          {{ currentItem.contactMan }}
        </el-form-item>
        <el-form-item label="联系电话:">
          {{ currentItem.phone }}
        </el-form-item>
        <el-form-item label="地址:">{{ currentItem.address }}</el-form-item>
        <el-form-item label="接管类型:">
          {{ currentItem.serviceType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>
        <el-form-item label="作物类型:">
          {{ currentItem.serviceScope }}
        </el-form-item>
        <el-form-item label="农事环节:">
          {{ currentItem.operScope }}亩
        </el-form-item>
        <el-form-item label="意向区域:">
          {{ currentItem.intentionRegion }}
        </el-form-item>
        <el-form-item label="可接管面积:">
          {{ currentItem.serviceArea }}亩
        </el-form-item>
        <el-form-item label="已接管面积:">
          {{ currentItem.serviceAreaOlde }}亩
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>


        <el-form-item label="其他说明:">
          {{ currentItem.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: true,
      baseUrl: window.globalUrl.HOME_API,
      currentItem: {},
    };
  },
  mounted() {},
  computed: mapGetters(["userInfo"]),
  watch: {},
  methods: {
    // 构造详情条件
    init(obj) {
      // let pzzp = [];
      // if (obj.landCertificateUrl) {
      //   JSON.parse(obj.landCertificateUrl).forEach((item) => {
      //     pzzp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
      //   });
      // }
      // obj.pzzp = pzzp;
      let xczp = [];
      if (obj.photos) {
        JSON.parse(obj.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      obj.xczp = xczp;
      this.currentItem = obj;
    },
    back() {
      this.$emit("closeDialog2", false);
    },
    handleClose() {
      this.$emit("closeDialog2", false);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
}
</style>
