<template>
  <div class="wytg">
    <AddEdit v-if="showEdit" @closeDialog="closeDialog" ref="addEdit" />
    <ShowDetail v-if="showDetail" @closeDialog2="closeDialog2" ref="showDetail" />
    <div class="form-list">
      <div class="table-search">
        <h3>我要接管</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
        <div class="tools">
          <el-button type="success" @click="add">发布新接管</el-button>
        </div>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pnum"
          :pageSize="query.psize"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
          @selectionChange="handleSelection"
        >
          <template slot="status" slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "启用" : "停用" }}</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="detailClick(scope.row)"
              >查看</el-button
            >
            <el-button type="text" size="small" @click="editClick(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="delClick(scope.row.id)"
              >删除</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="updateStatus(scope.row)"
              >{{ scope.row.status == 1 ? "停用" : "启用" }}</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
<script>
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import AddEdit from "./addEdit";
import ShowDetail from "./showDetail";
import { wyjgColumn } from "../tableColumn";
import { getCompanyToPage } from "@/api/projectManage";
import { deleteCompany,updateStatus } from "@/api/add";
const Query = [
  {
    name: "serviceScope",
    label: "作物类型：",
    componentType: "input",
    placeholder: "",
    width: "160px",
    inputType: "",
    // dataOptions: [],
  },
  {
    name: "operScope",
    label: "农事类型：",
    componentType: "input",
    placeholder: "",
    width: "160px",
    inputType: "",
    // dataOptions: [],
  },
  // {
  //   name: "orderStatus",
  //   label: "成交状态：",
  //   componentType: "select",
  //   placeholder: "请选择",
  //   width: "160px",
  //   dataOptions: [
  //     {
  //       name: "已成交",
  //       value: 0,
  //     },
  //     {
  //       name: "未成交",
  //       value: 1,
  //     },
  //   ],
  // },
];
export default {
  components: {
    FormQuery,
    TablePage,
    AddEdit,
    ShowDetail
  },
  filters: {
    name1Filter(value) {
      return value == 1 ? "全程托管" : "环境托管";
    },
  },
  data() {
    return {
      showEdit: false,
      showDetail:false,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      Query: Query,
      query: {
        pnum: 1,
        psize: 10,
      },
      total: 0,
      tableData: [],
      columns: wyjgColumn,
      tableData: [],
      options: [
        { value: 0, label: "已成交" },
        { value: 1, label: "未成交" },
      ],
    };
  },
  watch: {
    keyForm(n, o) {
      console.log("keyForm", n);
    },
  },
  created() {
    // this.getCropList();
    // this.getoperScope();
    this.getList();
  },
  methods: {
    closeDialog2(){
      this.showDetail = false;
    },
    // 点击详情
    detailClick(val){
      this.showDetail=true;
      this.$nextTick(()=>{
        this.$refs.showDetail.init(val);
      })
    },
    // 更新停用 启用状态
    async updateStatus(obj){
      let res = await updateStatus({
        id:obj.id,
        status:obj.status==0?1:0
      })
      if(res.code==0){
        this.$message.success(res.msg);
        this.getList();
      }else{
        this.$message.info(res.msg);
      }
    },
    // 关闭弹框
    closeDialog() {
      this.showEdit = false;
    },
    // 新增 编辑弹框出现
    add() {
      this.showEdit = true;
    },
    handleSelection(selects) {
      console.log(selects);
    },

    handleSelectChange() {
      this.page = 1;
      this.getList();
    },
    // 分页点击
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    // 分页数量改变
    handleSizeChange(size) {
      this.query.pnum = 1;
      this.query.psize = size;
      this.getList();
    },
    //点击编辑
    editClick(b) {
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.addEdit.editObj(b);
      });
    },
    // 点击删除
    delClick(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteCompany(id);
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getList();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 获取列表
    async getList() {
      let res = await getCompanyToPage(this.query);
      if (res.status) {
        this.tableData = res.data;
        this.total = res.dataNum;
      } else {
        this.tableData = [];
        this.total = 0;
        this.$message.error("查询失败");
      }
    },
    // 条件搜索
    search(params) {
      console.log(params);
      this.query = { ...this.query, ...params };
      this.getList();
    },
    submit() {},
    // 重置
    reset() {
      this.query = {};
      this.query.pnum = 1;
      this.query.psize = 10;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;

    /deep/.el-button {
      padding: 10px 20px !important;
    }

    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }

  .table-content {
    padding: 0 30px;
  }
}
</style>
